<template>
  <main class="mb-0 about-us-page contact-page">
    <section class="pages-path d-flex my-3 py-2 px-3 container bg-white box-shadow20 border-radius15 ">
      <svg class="ml-2" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
            stroke="#999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor"
           class="ml-2 mt-1 bi bi-chevron-double-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              fill="#a5a5a5"/>
        <path fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              fill="#a5a5a5"/>
      </svg>
      <router-link to="/" class="fontsize12 text-color-666">خانه</router-link>
      <svg class="mx-2 mt-1" width="12" height="12" viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5 19L8.5 12L15.5 5" stroke="#a5a5a5" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>
      <span class="fontsize11 text-color-a5">درباره ما</span>
    </section>
    <hr class="w-100 container d-md-block d-none bg-color-fa"/>
    <section class=" text-right about-us pb-3">
      <div class="back-about-us container pt-4 mb-3">
        <h5 class="weight-bold"><span class="text-color-themeBlue weight-bold">درباره ی آلبا مارکت  </span>بیشتر
          بدانید...</h5>
        <p v-if="$store.state.settings" class="fontsize12 text-justify text-color-444 line-height2">
          {{ $store.state.settings.filter(item => item.key == 'about_us_1')[0].value }}
        </p>
        <div v-else class="pt-3 w-100">
          <b-skeleton class="w-100 ml-2"></b-skeleton>
          <b-skeleton class="w-100 ml-2"></b-skeleton>
          <b-skeleton class="w-100 ml-2"></b-skeleton>
        </div>
        <section v-if="$store.state.settings" class="linus-history text-white  py-3 px-4">
          <h6 class="weight-bold">تاریخچه آلبا مارکت </h6>
          <p class="fontsize12 text-justify line-height2">
            {{ $store.state.settings.filter(item => item.key == 'about_us_2')[0].value }}
          </p>
        </section>
      </div>
      <div v-if="$store.state.settings" class="container">
        <p class="fontsize12 text-justify text-color-444 line-height2">
          {{ $store.state.settings.filter(item => item.key == 'about_us_3')[0].value }}
        </p>
      </div>
    </section>
  </main>
</template>
<script>

export default {
  components: {
  },
  metaInfo() {
    return {
      meta: [
        {property: 'og:title', content: 'درباره ما'},
      ]
    }
  }
}
</script>
